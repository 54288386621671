import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Base, LineMaintenanceDto, SwapPointsSettings, SwapPointDto } from './engineering.model';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';

@Injectable({
  providedIn: 'root'
})
@ConfigEndpoint({ version: 2 })
export class EngineeringHttpService {
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getEngineeringAirports(seasonCode: SeasonCode): Observable<Base[]> {
    return this.http.get<Base[]>(`${this.apiUrl}/${seasonCode}/engineering/line-maintenance/common/bases`);
  }

  getLineMaintenance(seasonCode: SeasonCode): Observable<LineMaintenanceDto[]> {
    return this.http.get<LineMaintenanceDto[]>(`${this.apiUrl}/${seasonCode}/engineering/line-maintenance`);
  }

  addLineMaintenance(seasonCode: SeasonCode, LlneMaintenance: LineMaintenanceDto): Observable<LineMaintenanceDto> {
    return this.http.post<LineMaintenanceDto>(
      `${this.apiUrl}/${seasonCode}/engineering/line-maintenance`,
      LlneMaintenance
    );
  }

  updateLineMaintenance(seasonCode: SeasonCode, lineMaintenance: LineMaintenanceDto): Observable<LineMaintenanceDto> {
    return this.http.put<LineMaintenanceDto>(
      `${this.apiUrl}/${seasonCode}/engineering/line-maintenance/${lineMaintenance.id}`,
      lineMaintenance
    );
  }

  deleteLineMaintenance(seasonCode: SeasonCode, LlneMaintenanceId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${seasonCode}/engineering/line-maintenance/${LlneMaintenanceId}`);
  }

  getSwapPoints(seasonCode: SeasonCode): Observable<SwapPointDto[]> {
    return this.http.get<SwapPointDto[]>(`${this.apiUrl}/${seasonCode}/engineering/swap-point`);
  }

  addSwapPoints(seasonCode: SeasonCode, swapPoint: SwapPointDto): Observable<SwapPointDto> {
    return this.http.post<SwapPointDto>(`${this.apiUrl}/${seasonCode}/engineering/swap-point`, swapPoint);
  }

  updateSwapPoints(seasonCode: SeasonCode, swapPoint: SwapPointDto): Observable<SwapPointDto> {
    return this.http.put<SwapPointDto>(
      `${this.apiUrl}/${seasonCode}/engineering/swap-point/${swapPoint.id}`,
      swapPoint
    );
  }

  deleteSwapPoints(seasonCode: SeasonCode, id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${seasonCode}/engineering/swap-point/${id}`);
  }

  updateSwapPointsSettings(settings: SwapPointsSettings, seasonCode: SeasonCode): Observable<SwapPointsSettings> {
    return this.http.put<SwapPointsSettings>(`${this.apiUrl}/${seasonCode}/engineering/swap-point/settings`, {
      ...settings,
      season: seasonCode
    });
  }

  fetchSwapPointsSettings(seasonCode: SeasonCode): Observable<SwapPointsSettings> {
    return this.http.get<SwapPointsSettings>(`${this.apiUrl}/${seasonCode}/engineering/swap-point/settings`);
  }
}
